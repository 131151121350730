.exportFlexiBeeSettingsWindow {
  width: 420px;
  :global(.input) {
    &-group-prepend {
      > span {
        width: 104px;
      }
    }
  }
}
