@import "config";

.table {
  white-space: nowrap;
  .monthCell {
    flex: 8;
    width: 8px;
    svg {
      font-size: 1rem;
      + span {
        margin-left: 0.25rem;
      }
    }
  }
  .transactionsAmountCell {
    flex: 10;
    justify-content: flex-end;
    width: 10px;
  }
  .transactionsCell {
    flex: 8;
    width: 8px;
    padding-left: 2rem;
    a {
      color: $uiDarkColor;
    }
  }
  .documentsAmountCell {
    flex: 10;
    justify-content: flex-end;
    width: 10px;
  }
  .documentsVatCell {
    flex: 10;
    justify-content: flex-end;
    width: 10px;
  }
  .documentsCell {
    flex: 8;
    width: 8px;
    padding-left: 2rem;
    a {
      color: $uiDarkColor;
    }
  }
  .actionsCell {
    position: relative;
    flex: none;
    justify-content: center;
    width: 212px;
    overflow: visible;
    > div {
      width: 100%;
    }
    button {
      width: 100%;
      padding: 0.35rem;
    }
    .footerExportSelector {
      font-weight: 500 !important;
    }
  }
  .tableHead {
    .monthCell {
      > div {
        margin-left: 1.25rem;
      }
    }
  }
  .tableRow {
    font-weight: 500;
    cursor: pointer;
    &.total {
      > div {
        background-color: $uiBackgroundColor;
      }
    }
  }
  .subRow {
    border-top: none !important;
    > div {
      padding-top: $contentDefaultIndent / 2;
      padding-bottom: $contentDefaultIndent / 2;
    }
    .monthCell {
      .subRowDate {
        padding-left: $contentDefaultIndent * 2.5;
      }
    }
  }
}
